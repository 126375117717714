import React from 'react';
import {useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import NavigationDropdown from './NavigationDropdown';

import pages from './../data/navigationPageConfig.js';

function Navigation() {
	const cookies = new Cookies();
	const [anchorElNav, setAnchorElNav] = React.useState(null);
	const [authenticated, setAuthenticated] = useState(false);

	const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};
	
	const handleLogout = () => {
		cookies.remove('authToken')
		setAuthenticated(false)
	}

	useEffect(() => {
		if(cookies.get('authToken')){
			if(!authenticated){
				setAuthenticated(JSON.parse(window.atob(cookies.get('authToken').split('.')[1])).authenticated)
			}
		} else {
			if(authenticated){
				setAuthenticated(false)
			}
		}
	}, [authenticated, cookies.get('authToken')])

	return (
		<AppBar position="static" className='navigationAppBar'>
			<Container maxWidth="xl" className='navigationContainer'>
				<Toolbar disableGutters>
					<div className='navigationLogoContainer'>
					<Link to='/' className='navigationLogoLink'> 
						<Container sx={{ width:'180px'}}>
							<Typography className='navigationLogoText' variant='h1'>
								Dreamforge
							</Typography>
						</Container>
					</Link>
					</div>
					<div className='navigationMenuContainer'>
					<Box sx={{ flexGrow: 1, display: {xs: 'flex', md: 'none'}, align: 'right'}}>
						<IconButton
							size="large"
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleOpenNavMenu}
							color="inherit"
						>
							<MenuIcon />
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'right'
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
							sx={{
								display: {xs: 'block', md: 'none'},
							}}
						>
							{pages.map((page) => (
								<MenuItem className='navigationPrimaryLink' key={page.title.concat('-menu-dropdown')}><NavigationDropdown title={page.title} nested={page.pages}></NavigationDropdown></MenuItem>
							))}
							<MenuItem onClick={handleCloseNavMenu}>
							<Link to='/contact-us' className='navigationLink'>
								<Button className='navigationAllTopics' sx={{ textTransform : 'none'}}>
									Contact Us
								</Button>
							</Link>
							
							</MenuItem>
						</Menu>
					</Box>
					<Box sx={{ flexGrow: 1, display: {xs: 'none', md:'flex'}}}>
						{pages.map((page) => (
							<NavigationDropdown key={page.title.concat('-dropdown')} title={page.title} nested={page.pages}></NavigationDropdown>
						))}
					<Link to='/contact-us' className='navigationLink'>
						<Button className='navigationAllTopics' sx={{ textTransform : 'none'}}>
							Contact Us
						</Button>
					</Link>


					</Box>
					</div>
				</Toolbar>
			</Container>
		</AppBar>
	)
}

export default Navigation;
