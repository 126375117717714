import React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

function NavigationDropdown(props) {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const open = Boolean(anchorEl)

	const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const menuLabel = [props.title.toLowerCase(), '-basic-menu'].join('')
	const buttonLabel = [props.title.toLowerCase(), '-basic-button'].join('')

	return (
		<div>
		<Button
			id={buttonLabel}
			aria-controls={open ? menuLabel : undefined}
			aria-haspopup="true"
			aria-expanded={open ? 'true' : undefined}
			onClick={handleOpen}
			className='navigationLink'
			sx={{ color: 'black'}}
			style={{fontSize: '18px'}}
		>
			{props.title}
		</Button>
		<Menu
			id={menuLabel}
			anchorEl={anchorEl}
			open={open}
			onClose={handleClose}
			MenuListProps={{
				'aria-labelledby': {buttonLabel}
			}}
		>
		{props.nested.map((page) => (
			<Link key={page.concat('-dropdown-link')} to={['/tags/', page.toLowerCase()].join('')} className='navigationLinkDropdown'>
				<MenuItem key={page} onClick={handleClose}>
					{page}
				</MenuItem>
			</Link>
		))}
		</Menu>
		</div>
	)
}

export default NavigationDropdown;
