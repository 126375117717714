import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material/styles';
import Navigation from './../layouts/Navigation.jsx';
import Footer from './../layouts/Footer.jsx';


const PrivacyPolicy = lazy(() => import('./../pages/PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('./../pages/TermsAndConditions'));
const ContactUs = lazy(() => import('./../pages/ContactUs'));
const About = lazy(() => import('./../pages/About'));

const LandingPage = lazy(() => import('./../pages/LandingPage'));
const ServicesPage = lazy(() => import('./../pages/ServicesPage'));

const ImageAttribution = lazy(() => import('./../pages/ImageAttribution'));

function MainRouter() {
  return (
	<StyledEngineProvider injectFirst>
		<Router>
			<div className='appContainer'>
			<Navigation />
			<Suspense fallback={<div className='mainLoadingDiv'>Loading...</div>}>
			<Routes>
				<Route path='/image-attribution' element={ <ImageAttribution /> } />
				<Route path='/privacy-policy' element={ <PrivacyPolicy /> } />
				<Route path='/terms-and-conditions' element={ <TermsAndConditions /> } />
				<Route path='/contact-us' element={ <ContactUs /> } />
				<Route path='/about' element={ <About /> } />
				<Route path='/services' element={ <ServicesPage /> } />
				<Route path='/' element={ <ServicesPage /> } />
			</Routes>
			</Suspense>
			<Footer />
			</div>
			<Suspense fallback={<div>Loading Cookie Banner</div>}>
			</Suspense>
		</Router>
	</StyledEngineProvider>
  );
}

export default MainRouter;
